"use strict";

var _interopRequireDefault = require("D:/workspace-java/feiniao-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/workspace-java/feiniao-website/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("../views/index.vue"));
_vue.default.use(_vueRouter.default);
const routes = [{
  path: '/',
  name: 'index',
  component: _index.default
}, {
  path: '/register',
  name: 'register',
  // route level code-splitting
  // this generates a separate chunk (register.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require(/* webpackChunkName: "register" */'../views/register.vue')))
}, {
  path: '/authPage',
  name: 'authPage',
  // route level code-splitting
  // this generates a separate chunk (register.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require(/* webpackChunkName: "register" */'../views/authPage.vue')))
}];
const router = new _vueRouter.default({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
var _default = exports.default = router;