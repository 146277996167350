"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "wrapper"
  }, [_c("div", {
    staticClass: "box-content"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("欢迎您授权飞鸟商品管理")]), _c("p", {
    staticClass: "txt"
  }, [_vm._v(" 店铺授权码 ")]), _c("el-input", {
    staticStyle: {
      width: "280px",
      "margin-right": "20px",
      "margin-top": "10px"
    }
  }), _c("el-button", {
    attrs: {
      size: "small"
    }
  }, [_vm._v("复制授权码")]), _vm._v(" " + _vm._s(_vm.codeUrl) + " ")], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;