"use strict";

var _interopRequireDefault = require("D:/workspace-java/feiniao-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
// import {
//   getToken
// } from '@/utils/auth'

// import {
//   isPhone
// } from "@/libs/wechat";

const service = _axios.default.create({
  baseURL: _settingMer.default.apiBaseURL,
  timeout: 60000 // 过期时间
});

// request interceptor
service.interceptors.request.use(config => {
  // 发送请求之前做的
  const token = !_store.default.getters.token ? sessionStorage.getItem('token') : _store.default.getters.token;
  if (token) {
    config.headers['Authori-zation'] = token;
  }
  if (/get/i.test(config.method)) {
    config.params = config.params || {};
    config.params.temp = Date.parse(new Date()) / 1000;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(response => {
  const res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code === 401) {
    // to re-login
    _elementUi.Message.error('无效的会话，或者登录已过期，请重新登录。');
    location.href = '/login';
  } else if (res.code === 403) {
    _elementUi.Message.error('没有权限访问。');
  } else if (res.code === 500) {
    (0, _elementUi.Message)({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return res;
  }
  if (res.code !== 200 && res.code !== 401) {
    // if (isPhone()) { //移动端
    //   return Promise.reject(res || 'Error')
    // }
    (0, _elementUi.Message)({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject();
  } else {
    // return res.data
    return res;
  }
}, error => {
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;