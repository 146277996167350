"use strict";

var _interopRequireDefault = require("D:/workspace-java/feiniao-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  data() {
    return {
      code: '31a882a7487b4b118374c440854a4a4e07f027b3',
      //授权用的code码
      codeUrl: ''
    };
  },
  // 1OM6TPSM
  created() {
    this.getCode();
    // const url = new URL(window.location.href);
    // this.code = url.searchParams.get('code');
    // console.log(url,668798)
  },
  methods: {
    async getCode() {
      _axios.default.get('http://192.168.31.68:8081/api/v3/admin/pdd/admin/pddCallback').then(res => {
        console.log(res, 3423523);
      });
      this.codeUrl = res.authCode;
    }
  }
};