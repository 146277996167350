"use strict";

var _interopRequireDefault = require("D:/workspace-java/feiniao-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAuthCodeApi = getAuthCodeApi;
exports.getLoginPicApi = getLoginPicApi;
exports.registerAuth = registerAuth;
exports.sendSms = sendSms;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 *获取登录页图片
 */
function getLoginPicApi() {
  return (0, _request.default)({
    url: `/admin/getLoginPic`,
    method: 'get'
  });
}

/**
 * 获取授权码
 * @param prams
 */
function getAuthCodeApi(params) {
  return (0, _request.default)({
    url: '/v3/admin/pdd/admin/pddCallback',
    method: 'GET',
    params
  });
}

/**
 * 获取手机验证码
 * @param pram
 */
function sendSms(params) {
  return (0, _request.default)({
    url: `/admin/system/admin/auth/sendSms`,
    method: 'post',
    params
  });
}

/**
 * 注册用户
 * @param pram
 */
function registerAuth(data) {
  return (0, _request.default)({
    url: `/admin/system/admin/auth/websiteRegister`,
    method: 'post',
    data
  });
}