"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "register"
  }, [_vm._m(0), _c("div", {
    staticClass: "registerBox"
  }, [_c("div", {
    staticClass: "boxTop"
  }, [_vm._v("为了保护您的安全，请验证您的身份")]), _c("div", {
    staticClass: "registerContent"
  }, [_c("div", {
    staticClass: "registerTxt"
  }, [_vm._v("创建账户")]), _c("el-form", {
    ref: "form",
    staticStyle: {
      "margin-top": "50px"
    },
    attrs: {
      model: _vm.form,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "手机号码",
      prop: "account"
    }
  }, [_c("div", {
    staticClass: "ihoneBox"
  }, [_c("el-input", {
    staticStyle: {
      width: "335px"
    },
    attrs: {
      placeholder: "请输入手机号码",
      maxlength: "11"
    },
    model: {
      value: _vm.form.account,
      callback: function ($$v) {
        _vm.$set(_vm.form, "account", $$v);
      },
      expression: "form.account"
    }
  }), _vm.countState ? _c("div", {
    staticClass: "codeBtn",
    on: {
      click: _vm.handleVerCodeChange
    }
  }, [_vm._v("获取验证码")]) : _vm._e(), !_vm.countState ? _c("div", {
    staticClass: "codeBtn",
    on: {
      click: _vm.handleVerCodeChange
    }
  }, [_vm._v(" " + _vm._s(_vm.remainingTime) + "s后重新获取 ")]) : _vm._e()], 1)]), _c("el-form-item", {
    attrs: {
      label: "输入验证码",
      prop: "code"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入验证码"
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "设置密码",
      prop: "pwd"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入密码",
      "show-password": ""
    },
    model: {
      value: _vm.form.pwd,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pwd", $$v);
      },
      expression: "form.pwd"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    staticStyle: {
      width: "100%",
      "border-radius": "25px",
      margin: "30px 0"
    },
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("注册")])], 1)], 1)], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "logo"
  }, [_c("img", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: require("../assets//img//feiniaoLogo.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;