"use strict";

var _interopRequireDefault = require("D:/workspace-java/feiniao-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App.vue"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("@/assets/styles/index.scss");
require("@/assets/styles/ruoyi.scss");
require("@/assets/styles/base.scss");
_vue.default.use(_elementUi.default);

// global css
// ruoyi css

_vue.default.config.productionTip = false;
new _vue.default({
  router: _router.default,
  store: _store.default,
  render: h => h(_App.default)
}).$mount('#app');