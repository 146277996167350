"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _user = require("@/api/user");
var _default = exports.default = {
  data() {
    return {
      form: {},
      rules: {
        account: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          pattern: /^1[3-9]\d{9}$/,
          message: '请输入正确的手机号码',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      },
      countState: true,
      countdown: 60,
      loading: false
    };
  },
  computed: {
    remainingTime() {
      if (this.countdown == 0) {
        this.countState = true;
      } else {
        this.countState = false;
      }
      return this.countdown > 0 ? this.countdown : 0;
    }
  },
  created() {
    console.log(this.$route.query.promoterPhone);
  },
  methods: {
    // 获取验证码
    handleVerCodeChange() {
      let regex = /^1[3-9]\d{9}$/;
      if (regex.test(this.form.account) == false) {
        this.$message.error('请正确输入手机号码');
        return;
      }
      this.countState = false;
      this.startCountdown();
      let param = {
        phone: this.form.account
      };
      console.log(param);
      (0, _user.sendSms)(param).then(res => {
        console.log(res);
        this.form.key = res.data.key;
      });
    },
    // 倒计时60秒
    startCountdown() {
      if (!this.timer) {
        this.countdown = 60;
        this.countdown1 = 60;
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.promoterPhone = this.$route.query.promoterPhone;
          this.loading = true;
          (0, _user.registerAuth)(this.form).then(res => {
            console.log(res);
            this.loading = false;
            if (res.code == 200) {
              this.$message({
                message: '注册成功',
                type: 'success'
              });
              this.$router.push({
                path: '/'
              });
            }
          });
        }
      });
    }
  }
};