"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "divBox"
  }, [_vm._m(0), _c("div", {
    staticClass: "banner"
  }, [_c("el-carousel", {
    attrs: {
      interval: 5000,
      height: _vm.imgWidth / 3.2 + "px"
    }
  }, _vm._l(_vm.bannerList, function (item) {
    return _c("el-carousel-item", {
      key: item
    }, [_c("img", {
      staticStyle: {
        width: "100%",
        cursor: "pointer"
      },
      attrs: {
        src: item,
        alt: ""
      },
      on: {
        click: _vm.downloadChange
      }
    })]);
  }), 1)], 1), _c("div", {
    staticClass: "cellBox"
  }, [_c("el-row", {
    attrs: {
      gutter: 10
    }
  }, _vm._l(_vm.cellList, function (item, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: 6
      }
    }, [_c("div", {
      staticClass: "cellItem"
    }, [_c("img", {
      staticStyle: {
        width: "80px"
      },
      attrs: {
        src: item.imgUrl,
        alt: ""
      }
    }), _c("div", {
      staticClass: "cellTitle"
    }, [_vm._v(_vm._s(item.title))]), _vm._l(item.txt, function (tchild) {
      return _c("div", {
        key: tchild,
        staticClass: "cellTxt"
      }, [_vm._v(" " + _vm._s(tchild) + " ")]);
    })], 2)]);
  }), 1)], 1), _vm._m(1), _c("div", {
    staticClass: "introBox"
  }, [_c("el-row", _vm._l(_vm.introList, function (item, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: 8
      }
    }, [_c("div", {
      staticClass: "introItem"
    }, [_c("img", {
      staticStyle: {
        width: "134px"
      },
      attrs: {
        src: item.imgUrl,
        alt: ""
      }
    }), _c("div", {
      staticClass: "introTitle"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "introTxt"
    }, [_c("div", {
      staticStyle: {
        width: "210px"
      }
    }, [_vm._v(_vm._s(item.txt))])])])]);
  }), 1)], 1), _c("div", {
    staticClass: "introduceBox"
  }, _vm._l(_vm.introduceList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "introduce"
    }, [_c("div", {
      staticClass: "introduceTitle"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "introduceTxt"
    }, [_vm._v(" " + _vm._s(item.txt) + " ")]), _c("div", {
      staticClass: "introduceImg"
    }, [_c("img", {
      staticStyle: {
        width: "800px"
      },
      attrs: {
        src: item.imgUrl,
        alt: ""
      }
    })])]);
  }), 0), _c("div", {
    staticClass: "foot"
  }, [_vm._m(2), _c("div", {
    staticClass: "beian"
  }, [_c("div", [_vm._v("安徽鸿臻探科技有限公司")]), _c("span", {
    on: {
      click: _vm.toWebSite
    }
  }, [_vm._v("皖ICP备2024035617号")])])]), _c("div", {
    staticClass: "suspend"
  }, [_c("div", {
    staticClass: "suspendItem",
    on: {
      click: _vm.downloadChange
    }
  }, [_c("img", {
    staticStyle: {
      width: "50px"
    },
    attrs: {
      src: require("@/assets/img/win.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "itemTxt"
  }, [_vm._v("立即下载")])]), _c("div", {
    staticClass: "line"
  }), _c("el-popover", {
    attrs: {
      placement: "left",
      trigger: "click"
    }
  }, [_c("div", [_c("img", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      src: require("@/assets/img/kefuecode.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "suspendItem",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("img", {
    staticStyle: {
      width: "50px"
    },
    attrs: {
      src: require("@/assets/img/kefu.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "itemTxt"
  }, [_vm._v("微信客服")])])]), _c("div", {
    staticClass: "line"
  }), _vm.scrollNum > 10 ? _c("div", {
    staticClass: "suspendItem",
    on: {
      click: _vm.scrollToTop
    }
  }, [_c("img", {
    staticStyle: {
      width: "50px"
    },
    attrs: {
      src: require("@/assets/img/up.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "itemTxt"
  }, [_vm._v("返回顶部")])]) : _vm._e()], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "titleBox"
  }, [_c("img", {
    staticClass: "logoImg",
    attrs: {
      src: require("@/assets/img/feiniaoLogo.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "appImg"
  }, [_c("img", {
    staticStyle: {
      width: "800px"
    },
    attrs: {
      src: require("@/assets/img/1730906708777.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footTxt"
  }, [_c("span", [_vm._v("飞鸟工具箱")]), _c("div", {
    staticClass: "line"
  }), _c("span", [_vm._v("拼多多全能数据分析大师")])]);
}];
render._withStripped = true;