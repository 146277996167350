"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user.js");
var _default = exports.default = {
  data() {
    return {
      imgWidth: 0,
      imgHeight: 0,
      bannerList: [require("@/assets/img/banner1.png"), require("@/assets/img/banner2.png"), require("@/assets/img/banner3.png")],
      cellList: [{
        id: 1,
        imgUrl: require("@/assets/img/11.png"),
        title: "数据AI计算",
        txt: ["已最直观的方式，呈现精准结果", '用户行为真实反映，多渠道数据验证', '真实数据，关键点分析，洞察店铺实况']
      }, {
        id: 2,
        imgUrl: require("@/assets/img/22.png"),
        title: "自动发视频",
        txt: ["全程无需干预，系统自动上传，轻松省事", '支持多条链接，同时启动发布，速度更快', '支持页面最小化不影响电脑其他操作']
      }, {
        id: 3,
        imgUrl: require("@/assets/img/33.png"),
        title: "店铺IP隔离",
        txt: ['多店铺登录，数量不限，可直接进入后台', '多人，多地，同时登录，团队高效合作', '登录环境安全加密，实时检测，云端更新']
      }, {
        id: 4,
        imgUrl: require("@/assets/img/44.png"),
        title: "爆款中心",
        txt: ['包含全网爆款，大促爆款、今日爆款', '后台真实数据，点同款截流，一键直达', '根据店铺类目，推荐当前热门爆款']
      }, {
        id: 5,
        imgUrl: require("@/assets/img/55.png"),
        title: "仅退款追回",
        txt: ['操作简单，高效运作一键开启自动申诉', '退款难题如泰山，软件一用变平川', '反复验证，全力出击，守护每一分利润']
      }, {
        id: 6,
        imgUrl: require("@/assets/img/66.png"),
        title: "竞品下载",
        txt: ['可查看商品分类、发布时间、销售信息等', '一键下载所有信息，包含主图，详情页等', '支持自定义下载，可选文字、图片、视频']
      }, {
        id: 7,
        imgUrl: require("@/assets/img/77.png"),
        title: "原价卡大促",
        txt: ['若无比价，支持自动卡位，无需手动操作', '10年老运营，详细拆解卡位流程，手动十步骤']
      }, {
        id: 8,
        imgUrl: require("@/assets/img/88.png"),
        title: "视频采集",
        txt: ['支持关键词搜索/用户搜索，且免费下载', '支持抖音、快手两大平台，获取海量视频', '视频带货数据，一目了然，便与运营优化']
      }],
      introList: [{
        id: 1,
        imgUrl: require("@/assets/img/3.png"),
        title: "行业热销爆款",
        txt: '行业中热销的商品列表，对手以及运营得到成功的商品，商品款式，价格构成等等，可参考此类商品进行款式选择，价格调整，活动提报等。'
      }, {
        id: 2,
        imgUrl: require("@/assets/img/4.png"),
        title: "流量猎手获取",
        txt: '对商品进行监控后可以获得产品图片评论数据，包括商品总评论数，商品评论详情，不同星级评论数据占比，标签词数据以及评论数量的变化趋势，来帮助您更好的对您店铺商品以及竞品销售状况进行分析'
      }, {
        id: 3,
        imgUrl: require("@/assets/img/5.png"),
        title: "优质视频下载",
        txt: '关键词搜索，一键采集并下载快手，抖音，小红书等多个平台的商品相关优质视频，省时省力省人工，辅助完成爆款产品的打造放大店铺自然流量的占比'
      }],
      introduceList: [{
        id: 1,
        imgUrl: require("@/assets/img/6.png"),
        title: "站内爆款",
        txt: '行业中热销的商品列表，对手已经运营得到成功的商品，商品款式，价格构成等等， 可参考此类商品进行款式选择，价格调整，活动提报等'
      }, {
        id: 2,
        imgUrl: require("@/assets/img/7.png"),
        title: "站内爆款",
        txt: '通过软件查看飞鸟站外爆款的榜单数据，从而了解产品的最低价格以及销量，达到站内站外全方位分析，帮助商家更好的定位产品的价格以及选品的方向'
      }, {
        id: 3,
        imgUrl: require("@/assets/img/8.png"),
        title: "飞鸟下载",
        txt: '对商品进行监控后可以获得产品图片评论数据，包括商品总评论数，商品评论详情不同星级评论数据占比，标签词数据以及评论数量的变化趋势，来帮助您更好的对您的店铺以及竞品销售情况进行分析'
      }, {
        id: 4,
        imgUrl: require("@/assets/img/9.png"),
        title: "视频采集",
        txt: '关键词搜索，一键采集并下载快手，抖音，小红书等多个平台的商品相关优质视频，省时省力 省人工，辅助完成爆款产品的打造，放大店铺自然流量的占比'
      }, {
        id: 5,
        imgUrl: require("@/assets/img/10.png"),
        title: "智能计算",
        txt: '通过智能算法一键计算店铺单品评分，商品利润率以及店铺流量分布，为店铺运营提供精准的数据支撑'
      }],
      scrollNum: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.updateImageSize();
    window.addEventListener('resize', this.updateImageSize);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateImageSize);
  },
  methods: {
    // 下载按钮
    downloadChange() {
      const url = 'https://wwue.lanzoue.com/inoIQ2c426sf';
      window.open(url, '_blank');
    },
    // 监听窗口大小变化
    updateImageSize() {
      // this.imgWidth = window.innerWidth;
      this.imgHeight = window.innerHeight;
      let width = window.innerWidth;
      if (width > 1280) {
        this.imgWidth = 1280;
      } else {
        this.imgWidth = width;
      }
    },
    // 监听滚动事件
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = scrollTop / (documentHeight - windowHeight);
      // console.log(`滚动位置：${scrollPosition * 100}`);
      this.scrollNum = scrollPosition * 100;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动效果
      });
    },
    getLoginPic() {
      (0, _user.getLoginPicApi)().then(res => {
        this.loginPic = res.data;
      });
    },
    toWebSite() {
      const url = 'https://beian.miit.gov.cn/';
      window.open(url, '_blank');
    }
  }
};