// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/register.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".register[data-v-77453986] {\n  height: 100vh;\n  width: 100vw;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n}\n.logo[data-v-77453986] {\n  width: 188px;\n  height: 47px;\n  position: absolute;\n  top: 43px;\n  left: 60px;\n}\n.registerBox[data-v-77453986] {\n  width: 592px;\n  min-height: 217px;\n  background: #FFFFFF;\n  border-radius: 37px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: 160px;\n}\n.registerBox .boxTop[data-v-77453986] {\n  height: 55px;\n  background-color: #1890FF;\n  border-top-right-radius: 37px;\n  border-top-left-radius: 37px;\n  padding: 0 66px;\n  line-height: 55px;\n  color: #FFFFFF;\n  font-size: 18px;\n}\n.registerBox .registerContent[data-v-77453986] {\n  padding: 0 66px;\n  margin-top: 68px;\n}\n.registerBox .registerContent .registerTxt[data-v-77453986] {\n  font-weight: bold;\n  font-size: 48px;\n  color: #202020;\n}\n.registerBox .ihoneBox[data-v-77453986] {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n.registerBox .ihoneBox .codeBtn[data-v-77453986] {\n  background: #E8F0FE;\n  padding: 0 20px;\n  cursor: pointer;\n  margin-left: -8px;\n  border-top-right-radius: 13px;\n  border-bottom-right-radius: 13px;\n}\n.registerBox .btn[data-v-77453986] {\n  width: 100%;\n  height: 50px;\n  background: #000000;\n  border-radius: 25px;\n  color: #fff;\n  text-align: center;\n  line-height: 50px;\n  font-size: 20px;\n  margin-top: 30px;\n  margin-bottom: 50px;\n  cursor: pointer;\n}\n.registerBox .btn[data-v-77453986]:hover {\n  background: #333;\n}\n[data-v-77453986] .el-input__inner {\n  background: #E8F0FE;\n  border-radius: 13px;\n  border: none;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
